
import { useLanguage } from "../../context/LanguageContext";
import styles from "./Districts.module.scss";
import { useTranslation } from "react-i18next";


function Districts ({district}){
    const { language } = useLanguage(); 
    const { t } = useTranslation();

    return(
        <div className={styles.regionWrapper}>
            <div>   
                {
                    Object.entries(district).map((item, idx)=>(
                        <div className={`${styles.regionRow}`}
                        key={idx} >
                            <p className={styles.regionName}>{item?.[1]?.options_data?.district?.name?.[language]}</p>
                            <p className={styles.regionNumber2}>{item?.[1]?.options_data?.count?.learn_count || 0} {t("pcs")}</p>
                            <p className={styles.regionNumber}>{item?.[1]?.options_data?.count?.found_flaw || 0} {t("pcs")}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
export default Districts;
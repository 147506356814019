import axios from "axios";

export const BASE_URL = "https://ijtimoiyinspeksiya.uz/api/v1";


const service = axios.create({
  baseURL: BASE_URL,
  timeout: 25000,
});



service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // if (error.response?.status === 500) {
    //   window.location.href = "/404"; 
    // }
  }
);

export default service;

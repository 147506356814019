import React from "react";
import styles from "./ContentBox.module.scss";


// icons
import { MdOutlineDateRange } from "react-icons/md";
import { FaYoutube, FaFacebookF, FaInstagram, FaTelegramPlane, FaEye  } from "react-icons/fa";


//components
import Social from "../social/Social";

function ContentBox({title, img, date, info, viewCount}) {


    return (
        <div className={styles.contentBox}>
            {
                img ? (
                    <img 
                    src={img} 
                    className={styles.img} 
                    alt={title}
                />
                ) : ("")
            }
            <h2 className={styles.title}>{title}</h2>
            {
                date ? (
                    <span className={styles.date}><MdOutlineDateRange />{date} <FaEye />{viewCount}</span>
                ) : (
                    ""
                )
            }

            <div className={styles.contentWrapper} dangerouslySetInnerHTML={{ __html: info }} />

            {
                date ? (
                    <Social 
                        youTube={"/"}
                        Facebook={"/"}
                        Intagram={"/"}
                        Telegram={"/"}
                        ButtonText={"Ijtimoiy tarmoqlarimizga a'zo bo'ling"}
                        buttonLink={"/"}
                    />
                ) : (
                    ""
                )
            }


        </div>
    );
}

export default ContentBox;

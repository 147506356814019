import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from "react-i18next";

//components
import Layout from "../layouts/Layout";
import CaruselBanner from "../components/carusel/Carusel"
import NewsSection from "../components/newsSection/NewsSection"
import LearnedObjects from "../components/learnedObjects/LearnedObjects"
import Partners from "../components/partners/Partners"
import UksNews from "../components/uksfnews/UksNews"


function Home (){
    const { t } = useTranslation();

    return(
        <HelmetProvider>
            <Helmet>
                <title>{t("siteName")}</title>
                <meta name="description" content="Ijtimoiy himoya milliy agentligi huzuridagi ijtimoiy inspeksiya" />
                <meta name="keywords" content="inspeksiya" />
                <link rel="canonical" href={`https://ijtimoiyinpeksiya.uz`}/>
            </Helmet>
            <Layout>
                <CaruselBanner/>
                <NewsSection/>
                <LearnedObjects/>
                <UksNews/>
                <Partners/>
            </Layout>
        </HelmetProvider>
    )
}
export default Home;


import { useTranslation } from "react-i18next";
import styles from "./LatestNewsCard.module.scss"

// images
import img1 from "../../assets/images/post1.png"

// icons
import { IoIosArrowRoundForward } from "react-icons/io";
import { FaRegCalendarAlt, FaEye } from "react-icons/fa";


function LatestNewsCard ({title, info, img, date, view, slug}){
    const { t } = useTranslation();
    return(
        <div className={styles.lastNews}>
            <img className={styles.newsImg} src={img}/>
            <div className={styles.lastNewsInfoWrapper}>
                <div className={styles.contentWrapper}>
                    <a href={`/article/${slug}`} className={styles.postTitle}>{title}</a>
                    <p className={styles.postInfo}>{info}</p>
                </div>
                <div className={styles.dateBox}>
                    <p className={styles.postDate}><FaRegCalendarAlt />{date}  <FaEye className={styles.eyeCount}/>{view}</p>
                    <a href={`/article/${slug}`} className={styles.moreLink}>{t("moreBtn")}<IoIosArrowRoundForward /></a>
                </div>
            </div>
        </div>
    )
}

export default LatestNewsCard;
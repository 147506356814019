import styles from "./RightSidebar.module.scss";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import request from "../../config/app-global";
import { useLanguage } from "../../context/LanguageContext"; 
import { useTranslation } from "react-i18next";

// icons
import { MdOutlineDateRange } from "react-icons/md";
import { FaEye } from "react-icons/fa";

function RightSidebar({ slug }) {
    const { t } = useTranslation();
    const [menu, setMenu] = useState();
    const { language } = useLanguage(); 

    const [fetchedNews, setFetchedNews] = useState([]);
    const [news, setNews] = useState([]); 

    useEffect(() => {
        // Fetch sidebar menus
        const fetchMenu = async () => {
            try {
                const res = await request.get(`/menu/sidebar?item=${slug}`);
                setMenu(res);
            } catch (error) {
                console.error("Error fetching menus:", error);
            }
        };       

        fetchMenu();        
    }, [language, slug]);

    // Fetch latest posts
    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const res = await request.get(`/article`);
                setFetchedNews(res);
            } catch (error) {
                console.error("Error fetching articles:", error);
            }
        };
        fetchArticles();
    }, [language, slug]); 

    useEffect(() => {
        if (fetchedNews) {
            setNews(fetchedNews);
        }
    }, [fetchedNews]);

    return (
        <div className={styles.sidebar}>
            <h2 className={styles.title}>{menu ? menu?.parent?.name?.[language] : t("latestNews")}</h2>

            <ul className={styles.list}>
                {
                    menu ? (
                        menu?.parent?.menus.map((el) => (
                            el?.item === slug ? (
                                <li key={el?.id} className={styles.activeItem}>
                                    <Link className={styles.sidebarLink} to={`/${el?.type?.label}/${el?.item}`}>{el?.name[language]}</Link>
                                </li>
                            ) : (
                                <li key={el?.id}>
                                    <Link className={styles.sidebarLink} to={`/${el?.type?.label}/${el?.item}`}>{el?.name[language]}</Link>
                                </li>
                            )
                        ))
                    ) : (
                        news?.data?.items?.slice(0, 5)?.map((post) => (
                            <li key={post?.id} className={styles.post}> 
                                <Link to={`/article/${post?.slug}`} className={styles.postLink}>
                                    <h2 className={`${styles.postTitle} ${post?.slug === slug ? styles.active : ""}`}>{post?.title?.[language]}</h2>
                                    <span className={styles.postDate}><MdOutlineDateRange />{post?.published_at}<FaEye />{post?.view_count}</span>
                                </Link>
                            </li>
                        ))
                    )
                }
            </ul>
        </div>
    );
}

export default RightSidebar;

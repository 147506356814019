
import React, { useState, useEffect } from "react";
import request from "../../config/app-global";
import { useLanguage } from "../../context/LanguageContext"; 
import { useParams } from "react-router-dom";
import styles from "./Statistic.module.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from "react-i18next";

import { StatisticsProvider, useStatistics } from '../../context/StatisticsContext';

//components
import LayoutView from "../../layouts/layoutView/LayoutView";
import Container from "../../components/globals/container/Container";
import ServiceRow from "../../components/serviceRow/ServiceRow"
import LearnedObjects from "../../components/learnedObjects2/LearnedObjects2";
import Breadcrump from "../../components/breadcrump/Breadcrump";
import Regions from "../../components/regions/Regions";


function StatisticsInner (){
    const { slug } = useParams();
    const { language } = useLanguage(); 
    const { t } = useTranslation();
    
    const [data, setData] = useState([]);
    const [statistics, setStatistics] = useState([])

    const [item, setItem ] = useState()
    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpandedService, setIsExpandedService] = useState(false);
    const [activeService, setActiveService] = useState(null);

    const [fetchYear, setFetchYear] = useState([])
    const { year, setYear, quarter, setQuarter} = useStatistics();

    useEffect(() => {
        // Fetch data
        const fetchArticl = async () => {
            try {
              const res = await request.get(`/statistic-category/view?id=${slug}&expand=data,items`);
              setIsExpanded(false);
              setData(res);
            //   console.log("slug", slug, "res", data )
            } catch (error) {
              console.error("Error fetching menus:", error);
            }
        };

        // Fetch years
        const fetchYear = async () => {
            try {
              const res = await request.get(`/statistic-data/year`);
              setFetchYear(res);
              setYear(res?.[res.length - 1]?.year);
            } catch (error) {
              console.error("Error fetching menus:", error);
            }
        };

        fetchArticl();
        fetchYear();
    }, [slug, language, setYear]); 

    useEffect(() => {
        if (data) {
            setStatistics(data);
        }
    }, [data]);

    // Handle year change
    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    // Handle quarter button click
    const handleButtonClick = (value) => {
        setQuarter(value);
    };

    // Handle service expand
    const handleExpand = (index) => {
        setItem(statistics?.items?.[index])
        setIsExpanded(true);
        setIsExpandedService(true)
        setActiveService(index);
    };

    return(
        <HelmetProvider>
            <LayoutView>
                <Helmet>
                    <title>{data?.name?.[language]}</title>
                    <meta name="description" content="" />
                    <meta name="keywords" content="statistika" />
                    <link rel="canonical" href={`https://ijtimoiyinpeksiya.uz/statistics/${slug}`}/>
                </Helmet>
                <div className={styles.bgWrapper}>
                    <Container>
                        <Breadcrump slug={slug}/>
                        <LearnedObjects/>
                        <div className={styles.section}>
                            <h2 className={styles.sectionTitle}>{statistics?.name?.[language]}</h2>
                            <div className={styles.buttonWrapper}>
                                <select value={year} onChange={handleYearChange}>
                                    {fetchYear?.map((item, idx) => (
                                        <option key={idx} value={item?.year}>{item?.year}</option>
                                    ))}
                                </select>
                                <button
                                    className={`${styles.tabBtn} ${quarter === '1000' ? styles.btnActive : ''}`}
                                    onClick={() => handleButtonClick('1000')}
                                >
                                   {t("first")}
                                </button>
                                <button
                                    className={`${styles.tabBtn} ${quarter === '2000' ? styles.btnActive : ''}`}
                                    onClick={() => handleButtonClick('2000')}
                                >
                                    {t("second")}
                                </button>
                                <button
                                    className={`${styles.tabBtn} ${quarter === '3000' ? styles.btnActive : ''}`}
                                    onClick={() => handleButtonClick('3000')}
                                >
                                    {t("third")}
                                </button>
                                <button
                                    className={`${styles.tabBtn} ${quarter === '4000' ? styles.btnActive : ''}`}
                                    onClick={() => handleButtonClick('4000')}
                                >
                                    {t("fourth")}
                                </button>
                                <button
                                    className={`${styles.tabBtn} ${quarter === '' ? styles.btnActive : ''}`}
                                    onClick={() => handleButtonClick('')}
                                >
                                    {t("year")}
                                </button>
                            </div>

                            <div className={styles.infoWrapper}>
                                <div className={`${styles.leftService} ${isExpandedService ? styles.expanded : ""}`}>
                                    {statistics?.items?.map((item, index) => (
                                        <div key={index} className={`${styles.row} ${activeService === index ? styles.serviceActive : ""}`}
                                            onClick={() => handleExpand(index)}>
                                            <span dangerouslySetInnerHTML={{ __html: item?.icon }} />
                                            <h4 className={`${styles.serviceBtn} `}>{item?.name[language]}</h4>
                                        </div>
                                    ))}
                                </div>
                                <div className={`${styles.rightInfoBox} ${isExpanded ? styles.expanded : ""}`}>
                                    {
                                        item?.items?.length === 0 ? (
                                            <Regions categoryId={item?.id} />
                                        ) : <ServiceRow data={item?.items} />
                                    }
                                </div>
                            </div>
                            
                        </div>
                    </Container>
                </div>
            </LayoutView>
        </HelmetProvider>
    )
}

function Statistics() {
    return (
        <StatisticsProvider>
            <StatisticsInner />
        </StatisticsProvider>
    );
}

export default Statistics;


























// import React, { useState, useEffect } from "react";
// import request from "../../config/app-global";
// import { useLanguage } from "../../context/LanguageContext"; 
// import { useParams } from "react-router-dom";
// import styles from "./Statistic.module.scss";
// import { Helmet, HelmetProvider } from 'react-helmet-async';

// //components
// import LayoutView from "../../layouts/layoutView/LayoutView";
// import Container from "../../components/globals/container/Container";
// import ServiceRow from "../../components/serviceRow/ServiceRow"
// import LearnedObjects from "../../components/learnedObjects2/LearnedObjects2";
// import Breadcrump from "../../components/breadcrump/Breadcrump";


// function Statistics (){
//     const { slug } = useParams();
//     const { language } = useLanguage(); 
    
//     const [data, setData] = useState([]);
//     const [statistics, setStatistics] = useState([])

//     const [item, setItem ] = useState()
//     const [isExpanded, setIsExpanded] = useState(false);
//     const [isExpandedService, setIsExpandedService] = useState(false);
//     const [activeService, setActiveService] = useState(null);

//     const [fetchYear, setFetchYear] = useState([])
//     const [year, setYear] = useState()
//     const [quarter, setQuarter] = useState("")





//     useEffect(() => {
//         //malumotlarni olish
//         const fetchArticl = async () => {
//             try {
//               const res = await request.get(`/statistic-category/view?id=${slug}&expand=data,items`);
//               setIsExpanded(false);
//               setData(res);
//             } catch (error) {
//               console.error("Error fetching menus:", error);
//             }
//         };

//         //yillarni olish
//         const fetchYear = async () => {
//             try {
//               const res = await request.get(`/statistic-data/year`);
//               setFetchYear(res);
//               setYear(res?.[0].year)
//             } catch (error) {
//               console.error("Error fetching menus:", error);
//             }
//         };

//         fetchArticl();
//         fetchYear();
//     }, [slug, language]); 

//     useEffect(() => {
//         if (data) {
//             setStatistics(data);
//         }
//     }, [data]);

//     //yil select tanlanishi
//     const handleYearChange = (event) => {
//         setYear(event.target.value);
//     };

//     //choraklik buttonlar bosilishi
//     const handleButtonClick = (value) => {
//         setQuarter(value);
//     };

//     //chap rowlar bosilganda o'ng tomon ochilishi
//     const handleExpand = (index) => {
//         setItem(statistics?.items?.[index])
//         setIsExpanded(true);
//         setIsExpandedService(true)
//         setActiveService(index);
//     };




//     return(
//         <HelmetProvider>
//             <LayoutView>
//                 <Helmet>
//                     <title>{data?.name?.[language]}</title>
//                     <meta name="description" content="Bu mening sahifamning tavsifi." />
//                     <meta name="keywords" content="kalit so'zlar, shu yerda" />
//                     <link rel="canonical" href="https://ijtimoiyinpeksiya.uz/statistics/1" />
//                 </Helmet>
//                 <div className={styles.bgWrapper}>
//                     <Container>
//                         <Breadcrump slug={slug}/>
//                         <LearnedObjects/>
//                         <div className={styles.section}>
//                             <h2 className={styles.sectionTitle}>{statistics?.name?.[language]}</h2>
//                             <div className={styles.buttonWrapper}>
//                                 <select value={year} onChange={handleYearChange}>
//                                     {
//                                         fetchYear?.map((item, idx) =>(
//                                             <option key={idx} value={item?.year}>{item?.year}</option>
//                                         ))
//                                     }
//                                 </select>

//                                 <button
//                                     className={`${styles.tabBtn} ${quarter === '1000' ? styles.btnActive : ''}`}
//                                     onClick={() => handleButtonClick('1000')}
//                                 >
//                                     I chorak
//                                 </button>
//                                 <button
//                                     className={`${styles.tabBtn} ${quarter === '2000' ? styles.btnActive : ''}`}
//                                     onClick={() => handleButtonClick('2000')}
//                                 >
//                                     II chorak
//                                 </button>
//                                 <button
//                                     className={`${styles.tabBtn} ${quarter === '3000' ? styles.btnActive : ''}`}
//                                     onClick={() => handleButtonClick('3000')}
//                                 >
//                                     III chorak
//                                 </button>
//                                 <button
//                                     className={`${styles.tabBtn} ${quarter === '4000' ? styles.btnActive : ''}`}
//                                     onClick={() => handleButtonClick('4000')}
//                                 >
//                                     IV chorak
//                                 </button>
//                                 <button
//                                     className={`${styles.tabBtn} ${quarter === '' ? styles.btnActive : ''}`}
//                                     onClick={() => handleButtonClick('')}
//                                 >
//                                     Yillik
//                                 </button>
//                             </div>

//                             <div className={styles.infoWrapper}>
//                                 <div className={`${styles.leftService} ${isExpandedService ? styles.expanded : ""}` }>
//                                     {statistics?.items?.map((item, index) => (
//                                     <div key={index} className={`${styles.row} ${activeService === index ? styles.serviceActive : ""}`}
//                                         onClick={() => handleExpand(index)}>
//                                         <span dangerouslySetInnerHTML={{ __html: item?.icon }} />
//                                         <h4  className={`${styles.serviceBtn} `}>{item?.name[language]}</h4>
//                                     </div>
//                                     ))}
//                                 </div>
//                                 <div className={`${styles.rightInfoBox} ${isExpanded ? styles.expanded : ""}`}>
//                                     <ServiceRow 
//                                         data={item?.items ? item?.items : null}
//                                         year={year}
//                                         quarter={quarter}
//                                         />
//                                 </div>
//                             </div>
//                         </div>
//                     </Container>
//                 </div>
//             </LayoutView>
//         </HelmetProvider>
//     )
// }
// export default Statistics;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./TopBar.module.scss";
import useConfig from "../../config/config"


// icons
// import { IoLocationSharp, IoEyeSharp } from "react-icons/io5";
// import { FaThList, FaPhone } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
//2
import { PiEyeglassesLight } from "react-icons/pi";
import { LuPhone } from "react-icons/lu";
import { LiaSitemapSolid } from "react-icons/lia";
import { SlLocationPin } from "react-icons/sl";

// images
import Logo from "../../assets/svg/logo.png";

// components
import Container from "../globals/container/Container";
import MobileMenu from "../mobileMenu/MobileMenu";
import { useLanguage } from "../../context/LanguageContext"; 
import CrippleBox from "../crippleBox/Cripple";
import StickyNavbar from "../stickyNavbar/StickyNavbar";


function TopBar() {
  const { t } = useTranslation();
  const { language, changeLanguage } = useLanguage(); 
  const config = useConfig()

  const [menuOpen, setMenuOpen] = useState(false);
  const [burgervisible, setBurgervisible] = useState(true);
  const [visible, setVisible] = useState(false)
  // const [isFixed, setIsFixed] = useState(false);


  //gamburger bosilishi funksiyasi
  const handleBurgerClick = () => {
    setMenuOpen(true);
    setBurgervisible(false);
  };

  //burger ichidagi x bosilishi funksiyasi
  const handleCrossClick = () => {
    setMenuOpen(false);
    setBurgervisible(true);
  };

  //zaif ko'ruvchilar bosilish funcsiyasi
  const handleEyeClick = () => {
    setVisible(!visible);
    
  };


//stiky navbar uchun funksiya
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
      const scrollY = window.scrollY;
      
      if (scrollY > 160) { 
          setIsSticky(true);
      } else {
          setIsSticky(false);
      }
  };

  useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
          window.removeEventListener("scroll", handleScroll);
      };
  }, []);





  return (
    <>

      <div className={`${styles.header}`}>
      <marquee className={styles.marque}>Sayt test rejimida ishlamoqda</marquee>
        <Container>
          <div className={styles.contentWrapper}>
            <a href="/" className={styles.logoWrapper}>
              <img className={styles.mobileLogo} src={Logo} alt="Logo" />
              <h1 className={styles.siteTitle}>{t('siteName')}</h1>
            </a>
            <div className={styles.topHeader}>
              <a className={styles.telNumber} href={`tel:${config?.call_center}`}>
                <LuPhone className={styles.icon}/>{config?.call_center}</a>
              <p className={styles.address}>
                <SlLocationPin className={styles.icon}/>
                {config?.address?.[language] ? config?.address?.[language] : ""}
              </p>
              <p className={styles.eyes}  onClick={handleEyeClick}>
                <PiEyeglassesLight className={styles.icon}/>
                {t("eyes")}
              </p>



              <a className={styles.siteMap} href="/map">
                <LiaSitemapSolid className={styles.icon}/>
                {t("siteMap")}
              </a>
              <div className={styles.customselectwrapper}>
                <select
                  id="languageSelect"
                  className={styles.languageSelect}
                  value={language}
                  onChange={(e) => changeLanguage(e.target.value)}
                >
                  <option value={"luz"}>O'z</option>
                  <option value={"kuz"}>Уз</option>
                  <option value={"ru"}>Ru</option>
                  <option value={"eng"}>En</option>
                </select>
              </div>

              {burgervisible ? (
                <button className={styles.burgerBtn} onClick={handleBurgerClick}>
                  <GiHamburgerMenu size={35} />
                </button>
              ) : (
                <button className={styles.crossBtn} onClick={handleCrossClick}>
                  <RxCross2 size={40} />
                </button>
              )}
            </div>
          </div>
        </Container>
        {menuOpen && (
          <div className={styles.menuWrapper}>
            <MobileMenu />
          </div>
        )}
        {
          <div className={`${styles.crippleBox} ${visible ? styles.expand : styles.collapse}`}>
            <CrippleBox />
          </div>
        }
      <div className={`${styles.navbox} ${isSticky ? styles.expand : styles.collapse}`}>
        <StickyNavbar/>
      </div>
      </div>
    </>
  );
}

export default TopBar;

import styles from "./DocumentCard.module.scss";


//images
import Desktop from "../../assets/images/comp.png"

function DocumentCard ({title, url}){
    return(
        <div className={styles.card}>
            <a href={url} className={styles.link}>
                <img className={styles.image} src={Desktop} alt="icon"/>
                <p className={styles.title}>{title}</p>
            </a>
        </div>
    )
}

export default DocumentCard;;
import React from "react";
import styles from "./Footer.module.scss"
import { useTranslation } from "react-i18next";
import useSocialData from "../../config/social"
import useConfig from "../../config/config"
import { useLanguage } from "../../context/LanguageContext"; 

//icons
import { FaLocationDot, FaPhone  } from "react-icons/fa6";
import { IoMailSharp } from "react-icons/io5";
import { IoMdPrint } from "react-icons/io";
import { FaYoutube, FaFacebookF, FaInstagram, FaTelegramPlane, FaTwitter  } from "react-icons/fa";

//images
// import Logo from "../../assets/svg/logo.png"

// components
import Container from "../globals/container/Container";

function Footer (){
    const { t } = useTranslation();
    const { language } = useLanguage();
    const social = useSocialData();
    const config = useConfig();


    return (
        <div className={styles.footer}>
            <Container>
                <div className={styles.contentWrapper}>
                    <div className={styles.titleBox}>
                        <div className={styles.mapBox}>
                            <img className={styles.logo} src={config?.logo ? config?.logo : ""} alt={t("siteName")}/>
                            <h3>{t("siteName2")}<br/> <span>{t("siteName")}</span></h3>
                        </div>
                        
                    </div>

                    <div className={styles.contactBox}>
                        <h3>{t("contactTitle")}</h3>
                        <div>
                            <FaLocationDot className={styles.icons}/>
                            <p>{config?.address?.[language]}</p>
                        </div>
                        <a href={`mailto:${config?.email?.[0] ? config?.email?.[0] : ""}`}><IoMailSharp className={styles.icons}/>{config?.email?.[0] ? config?.email?.[0] : ""}</a>
                        <a href={`mailto:${config?.email?.[1] ? config?.email?.[1] : ""}`}><IoMailSharp className={styles.icons}/>{config?.email?.[1] ? config?.email?.[1] : ""}</a>
                        <a href={`tel:${config?.phone?.[0] ? config?.phone?.[0].replace(/\s+/g, "") :""}`}><FaPhone className={styles.icons}/>{config?.phone?.[0] ? config?.phone?.[0] :"" }</a>
                        <a href={`tel:${config?.phone?.[1] ? config?.phone?.[1].replace(/\s+/g, "") :""}`}> <IoMdPrint className={styles.icons}/>{config?.phone?.[1] ? config?.phone?.[1] :"" }</a>
                    </div>

                    <div className={styles.socialBox}>
                        <h3>{t("socialTitle")}</h3>
                        <div className={styles.iconWrapper}>
                            {
                                social?.map((item, idx) => {
                                if (item?.icon == 10000) {
                                    return <a href={item?.url} key={idx}><FaTelegramPlane  className={styles.socialIcons} /></a>;
                                } else if (item?.icon == 10100) {
                                    return  <a href={item?.url} key={idx}><FaInstagram  className={styles.socialIcons} /></a>;
                                } else if (item?.icon == 10200) {
                                    return <a href={item?.url} key={idx}><FaFacebookF className={styles.socialIcons}/></a>;
                                }  else if (item?.icon == 10500) {
                                    return <a href={item?.url} key={idx}><FaYoutube className={styles.socialIcons}/></a>;
                                } else if (item?.icon == 10300) {
                                    return <a href={item?.url} key={idx}><FaTwitter  className={styles.socialIcons}/></a>;
                                } else {
                                    return null; 
                                }})
                            }
                        </div>
                        <p>{config?.description?.[language]}</p>
                    </div>
                </div>
            </Container>
            <h6 className={styles.textBottom}>{t("footerP2")}</h6>
        </div>
    )
}

export default Footer;
import styles from "./ButtonWhite.module.scss"


function ButtonWhite ({text, link}){
    return (
        <>
        <a href={link} className={styles.newsLink}>{text}</a>
        </>
    )
}
export default ButtonWhite
import { useState, useEffect } from 'react';
import request from "./app-global";

const useMenuData = () => { // "menuData" o'rniga "useMenuData" deb o'zgartirish tavsiya etiladi
    const [menu, setMenu] = useState([]); // Boshlang'ich qiymat bo'sh massiv

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await request.get(`/menu`);
                setMenu(res); // To'g'ridan-to'g'ri menu holatini o'rnatamiz
            } catch (error) {
                console.error("Menyu olishda xato:", error);
            }
        };
        fetchData();
    }, []); // Faqat bir marta chaqiriladi

    return menu; // Menyuni qaytarish
};

export default useMenuData; // "menuData" o'rniga "useMenuData" deb nomlang

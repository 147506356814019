import styles from "./InfoCard.module.scss"




function InfoCard ({number, title, icon}){
    return (
        <div className={styles.card}>
            <div className={styles.iconBox} >
                <span dangerouslySetInnerHTML={{ __html: icon }} /> 
            </div>
            <div className={styles.countBox}>
                <h4>{number ? number : ""}</h4>
                <p>{title ? title : ""}</p>
            </div>
        </div>
    )
}
export default InfoCard;
import styles from "./UserCard.module.scss";
import { useLanguage } from "../../context/LanguageContext";
import { useTranslation } from "react-i18next";

// icons
import { IoMdMail } from "react-icons/io";
import { MdLocalPhone } from "react-icons/md";
import { GoStopwatch } from "react-icons/go";
import { FaLocationDot } from "react-icons/fa6";
import { RxCross1 } from "react-icons/rx";

// components
// import Social from "../social/Social";
import { useState } from "react";
import Container from "../globals/container/Container";
import SocialUser from "../socialUser/SocialUser"

function UserCard({ user }) {
    const { language } = useLanguage();
    const [visible, setVisible] = useState(false);
    const [contentName, setContentName] = useState("");
    const { t } = useTranslation();

    const handleClick = (evt) => {
        setContentName(evt.target.id);
        setVisible(!visible);
    };


    const getContent = (contentName) => {
        if (user?.description) {
            switch (contentName) {
                case 'about':
                    return user?.description?.about[language];
                case 'obligations':
                    return user?.description?.obligations[language];
                default:
                    return "";
            }
        }
        return "";
    };

    return (
        <div className={styles.card}>
            <img src={user?.photo} alt={user?.name[language]} className={styles.userimg} />
            <div className={styles.infoWrapper}>
                <h3 className={styles.name}>{user?.name[language]}</h3>
                <p className={styles.position}>{user?.role[language]}</p>
                <div className={styles.contactwrapper}>
                    <a href={`mailto:${user?.description?.mail}`}><IoMdMail />{user?.description?.mail}</a>
                    <a href={`tel:${user?.description?.phone}`}><MdLocalPhone />{user?.description?.phone}</a>
                    <a href={"#"}><GoStopwatch />{user?.description?.date[language]}</a>
                </div>
                {user?.description?.location && (
                    <p className={styles.location}><FaLocationDot />{user?.description?.location[language]}</p>
                )}
                
                    <div className={styles.socialWrapper}>
                    {user?.description?.links && (
                        <SocialUser
                            Facebook={user.description.links.Facebook || null}
                            Intagram={user?.description?.links?.Intagram || null}
                            Telegram={user?.description?.links?.Telegram || null}
                            Linkedin={user?.description?.links?.Linkedin || null}
                            Twitter={user?.description?.links?.twitter || null}
                            YouTube={user?.description?.links?.YouTube || null}
                        />
                        )}
                        {
                            user?.description?.about ? (
                                <div className={styles.aboutWrapper}>
                                    <button type="button" id="about" onClick={handleClick}>{t("job")}</button>
                                    {/* <button type="button" id="obligations" onClick={handleClick}>{t("tasks")}</button> */}
                                </div>
                            ) : ("")
                        }
                    </div>

                    <div className={`${styles.accordion} ${!visible ? styles.collapse : styles.expand}`}>
                        <h4>{contentName === 'about' ? t("activity") : t("tasks")}</h4>
                        <p>{getContent(contentName)}</p>
                    </div>
                
            </div>
            {/* modal */}
            {/* {visible && (
                <div className={styles.modal}>
                    <Container>
                        <button onClick={() => setVisible(false)} className={styles.closeButton}><RxCross1 /></button>
                        <div className={styles.modalInfoWrapper}>
                            <h4>{contentName === 'about' ? t("activity") : t("tasks")}</h4>
                            <p>{getContent(contentName)}</p>
                        </div>
                    </Container>
                </div>
            )} */}
        </div>
    );
}

export default UserCard;

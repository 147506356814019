import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./MapUzb.module.scss";

//images
import Uzb from "../../assets/svg/Map";

function MapUzb() {
    const navigate = useNavigate();

    const handlePathClick = (id) => {
        navigate(`/link/contact/${id}`);
    };  

    return (
        <div className={styles.section}>
            <Uzb className={styles.uzb} handleClick={handlePathClick} />
            <iframe className={styles.map2} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d47968.76997690218!2d69.14936574863279!3d41.28605710000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8b21432207a5%3A0xeb251640e4187f39!2z0KfQuNC70L7QvdC30L7RgCDRgtGD0LzQsNC9INCc0JjQkQ!5e0!3m2!1sru!2s!4v1720856182139!5m2!1sru!2s" width="300" height="150"   loading="lazy" ></iframe>
        </div>
    );
}

export default MapUzb;

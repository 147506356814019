import React from "react";
import { useLanguage } from "../../context/LanguageContext"; 
import { useTranslation} from "react-i18next";
import styles from "./NewsAll.module.scss";


// components
import NewsCard2 from "../newsCard2/NewsCard2";



function NewsAll({news}) {

    const {t} = useTranslation()
    const { language } = useLanguage(); 



    return (

        <div className={styles.wrapper}>
            <h2 className={styles.title}>{news?.category?.title[language]}</h2>
            <div className={styles.cardWrapper}>
                {
                    !news?.data?.items?.length ? (
                        <p>{t("noInfo")}</p>
                    ) : (

                        news?.data?.items?.map((post, idx)=>(
    
                            <NewsCard2
                                key={idx}
                                title={post?.title[language]}
                                img={post?.thumbnail_path}
                                date={post?.published_at}
                                slug={post?.slug}
                            />
                        ))
                    )
                }
            </div>
        </div>
    );
}
export default NewsAll;

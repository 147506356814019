import React, { useState } from "react";
import request from "../config/app-global";
import styles from "./pagestyles/ContactOnline.module.scss";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from 'react-helmet-async';

//images
import Logo from "../assets/svg/logo.png";
import { IoCheckmarkDone } from "react-icons/io5";

//components
import LayoutView from "../layouts/layoutView/LayoutView";
import Container from "../components/globals/container/Container";
import Breadcrump from "../components/breadcrump/Breadcrump";

function ContactOnline() {
  const { slug } = useParams();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    comment: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };




  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await request.post("/appeal/create", formData);
      console.log("Form submitted successfully:", res.data);
      setIsSubmitted(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <HelmetProvider>
      <LayoutView>
        <Helmet>
          <title>{t("contactme")}</title>
        </Helmet>
        <div className={styles.section}>
          <Container>
            <Breadcrump slug={slug}/>
            <div className={styles.titleWrapper}>
              <img src={Logo} alt="Logo" />
              <h4>{t("complaint")} <br /> {t("complaint2")}</h4>
            </div>

            {!isSubmitted ? (
              <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.labelWrapper}>
                  <label>
                    {t("perName")}
                    <input
                      required
                      name="name"
                      type="text"
                      placeholder="Ism sharifingiz"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </label>
                  <label>
                  {t("perMail")}:
                    <input
                      required
                      name="email"
                      type="email"
                      placeholder="gmail.com"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </label>
                  <label>
                    {t("phone")}:
                    <input
                      required
                      name="phone_number"
                      type="number"
                      placeholder="+998 "
                      value={formData.phone_number}
                      onChange={handleChange}
                    />
                  </label>
                </div>

                <label>
                  {t("offer")}:
                  <textarea
                    required
                    name="comment"
                    placeholder="Xabar matnini kiriting"
                    value={formData.comment}
                    onChange={handleChange}
                  />
                </label>
                <button type="submit">{t("send")}</button>
              </form>
            ) : (
              <div className={styles.succesfullBox}>
                <h4><IoCheckmarkDone /> {t("succefully")}</h4>
                <p>{t("respons")}</p>
                <div className={styles.linkWrapper}>
                  <a href="/link/appeall" className={styles.link}>{t("newOffer")}</a>
                  <a href="/" className={styles.link}>{t("redirektHome")}</a>
                </div>
              </div>
            )}
          </Container>
        </div>
      </LayoutView>
    </HelmetProvider>
  );
}

export default ContactOnline;

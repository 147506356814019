
import styles from "./Header.module.scss";

// components
import Navbar from "../navbar/Navbar";
import TopBar from "../topBar/TopBar";


function Header() {


    return (
        <div className={styles.header}>
            <TopBar />
            <Navbar />
        </div>
    );
}

export default Header;

import styles from "./pagestyles/Leadership.module.scss";
import React, { useState, useEffect } from "react";
import request from "../config/app-global";
import { useLanguage } from "../context/LanguageContext";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//components
import LayoutView from "../layouts/layoutView/LayoutView";
import Container from "../components/globals/container/Container";
import RightSidebar from "../components/rightSidebar/RightSidebar";
import UserCard from "../components/userCard/UserCard";
import Breadcrump from "../components/breadcrump/Breadcrump";

function Leadership() {
    const { slug } = useParams();
    const [users, setUsers] = useState([]);
    const [fetchedUsers, setFetchedUsers] = useState([]);
    const { language } = useLanguage();
    const { t } = useTranslation();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const res = await request.get(`/staff/list?type=${slug}`);
                setFetchedUsers(res.items);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };

        fetchUsers();
    }, [language, slug]);

    useEffect(() => {
        if (fetchedUsers.length > 0) {
            setUsers(fetchedUsers);
        }
    }, [fetchedUsers]);



    return (
        <LayoutView>
            <div className={styles.section}>
                <Container>
                <Breadcrump slug={slug}/>
                    <div className={styles.contentWrapper}>
                        <div className={styles.cardWrapper}>
                            <h2 className={styles.sectionTitle}>{slug == 1100 ? t("leader1") : slug == 2200 ? t("leader2") : slug == 3300 ? t("leader3") : slug == 4400 ? t("leader4") : "" }</h2>
                            {users.map((user, idx) => (
                                <UserCard key={idx} user={user} />
                            ))}
                        </div>
                        <RightSidebar slug={slug} />
                    </div>
                </Container>
            </div>
        </LayoutView>
    );
}

export default Leadership;

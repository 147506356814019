import React, { useState, useEffect } from "react";
import request from "../../config/app-global";
import { useLanguage } from "../../context/LanguageContext"; 
import styles from "./UksNews.module.scss";
import { useTranslation } from "react-i18next";
import {stripHtmlTags} from "../../helpers/stripHtmlTags"

// components
import Container from "../globals/container/Container";
import LatestNewsCard from "../latestNewsCard/LatestNewsCard";
import NewsCard from "../newsCard/NewsCard";
import ButtonWhite from "../globals/buttonWhite/ButtonWhite";


function UksNews() {
    const { t } = useTranslation();
    const { language } = useLanguage();
    const [article, setArticle] = useState(null);
    
    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const res = await request.get("/article?expand=body");
                setArticle(res);
            } catch (error) {
                console.error("Error fetching articles:", error);
            }
        };
        fetchArticle();
    }, [language]);

    // const latestPost = article?.data?.items?.[0] || null;

    const uksfnews = article?.data?.items?.filter((el)=> el?.selected === 1)


    return (
        <>
            <Container>
                <div className={styles.newsSection}>
                    <h2 className={styles.title}>{t("uksfnews")}</h2>
                    {/* {latestPost && (
                        <LatestNewsCard 
                            title={latestPost?.title[language]} 
                            info={stripHtmlTags(latestPost?.body[language]) }
                            img={latestPost?.thumbnail_path}
                            date={latestPost?.published_at}
                            slug={latestPost?.slug}
                            view={latestPost?.view_count}

                        />
                    )} */}
                    <div className={styles.cardWrapper}>
                        {uksfnews?.slice(0, 3)?.map((post, idx) => (
                            <NewsCard
                                key={idx}
                                title={post?.title[language]} 
                                info={ stripHtmlTags(post?.body[language]) }
                                img={post?.thumbnail_path}
                                date={post?.published_at}
                                slug={post?.slug}
                                view={post?.view_count}
                            />
                        ))}
                    </div>
                    <div className={styles.buttonWrapper}>
                        <ButtonWhite text={t("moreDetails")} link={"/category/all"} />
                    </div>
                </div>
            </Container>
        </>
    );
}

export default UksNews;

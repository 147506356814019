import styles from "./pagestyles/Contact.module.scss";
import React, { useState, useEffect } from "react";
import request from "../config/app-global";
import { useLanguage } from "../context/LanguageContext";
import { useParams } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

//components
import LayoutView from "../layouts/layoutView/LayoutView";
// import RightSidebar from "../components/rightSidebar/RightSidebar";
import LeftSidebar from "../components/leftSidebar/LeftSidebar";
import Container from "../components/globals/container/Container";
import MapUzb from "../components/mapUzb/MapUzb";
import ContactBanner from "../components/contactBanner/Contact";
import RegionCard from "../components/regionCard/RegionCard";
import Breadcrump from "../components/breadcrump/Breadcrump";

//images
// import Map from "../assets/svg/map.svg";
import Banner from "../assets/images/contactbanner.png";

function Contact() {
    const { slug } = useParams();
    const [data, setData] = useState([]);
    const [regions, setRegions] = useState([]);
    const [fetchedData, setFetchedData] = useState([]);
    const [fetchedRegions, setFetchedRegions] = useState([]);
    const { language } = useLanguage();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await request.get(`/contact/list`);
                setFetchedData(res);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const fetchRegion = async () => {
            try {
                const res = await request.get(`/region/list`);
                setFetchedRegions(res);
            } catch (error) {
                console.error("Error fetching regions:", error);
            }
        };
        
        fetchData();
        fetchRegion();
    }, [language, slug]);

    useEffect(() => {
        if (fetchedData) {
            setData(fetchedData);
        }
        if (fetchedRegions) {
            setRegions(fetchedRegions);
        }
    }, [fetchedData, fetchedRegions]);

    const findRegion = data?.items?.find(region => region?.region === slug || null);

    return (
        <HelmetProvider>
            <LayoutView>
                <Helmet>
                    <title>Biz bilan bog'lanish</title>
                </Helmet>
                <div className={styles.contactWrapper}>
                    <Container>
                        <Breadcrump slug={slug}/>
                        <div className={styles.contactSection}>
                            <LeftSidebar data={regions ? regions : null} slug={slug} />
                            <div className={styles.contentBox}>
                                {findRegion ? (
                                    <>
                                        <ContactBanner 
                                            title={"Sizda shikoyat yoki taklif bormi? Bizga offline murojaat qiling."} 
                                            img={findRegion?.banner ? findRegion?.banner : Banner} 
                                        />
                                        <RegionCard
                                            img={findRegion?.photo} 
                                            workTime={findRegion?.description?.date[language]}
                                            phone={findRegion?.description?.phone} 
                                            mail={findRegion?.description?.mail} 
                                            name={findRegion?.name[language]} 
                                            position={findRegion?.role[language]} 
                                            location={findRegion?.description?.location[language]} 
                                        />
                                    </>
                                ) : (
                                    <div>
                                        <MapUzb className={styles.map} />
                                        
                                    </div>
                                )}
                            </div>
                            {/* <RightSidebar/> */}
                        </div>
                    </Container>
                </div>
            </LayoutView>
        </HelmetProvider>
    );
}

export default Contact;

import styles from "./SocialUser.module.scss";


// icons
import { FaYoutube, FaFacebookF, FaInstagram, FaTelegramPlane, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

function SocialUser({Facebook, Intagram, Telegram, Linkedin, Twitter, YouTube}){
    return(
        <div className={styles.box}>
            <div className={styles.socialBox}>
                {YouTube ? <a href={YouTube}><FaYoutube /></a> : ""}
                {Facebook ? <a href={Facebook} ><FaFacebookF /></a> : ""}
                {Intagram ? <a href={Intagram} ><FaInstagram /></a> : ""}
                {Telegram ? <a href={Telegram} ><FaTelegramPlane /></a> : ""}
                {Twitter ? <a href={Twitter} ><FaXTwitter /></a> : ""}
                {Linkedin ? <a href={Linkedin} ><FaLinkedinIn /></a> : ""}
            </div>
        </div>
    )
}
export default SocialUser;
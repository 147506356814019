import React, { useState, useEffect } from "react";
import request from "../../config/app-global";
import { useLanguage } from "../../context/LanguageContext";
import styles from "./SearchInput.module.scss";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SearchInput() {
  const [query, setQuery] = useState("");
  // const [result, setResult] = useState(null);
  const { language } = useLanguage();
  const navigate = useNavigate(); // Use navigate for redirection
  const { t } = useTranslation();

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    if (query.trim()) {
      fetchData();
    }
  };

  const fetchData = async () => {
    try {
      const res = await request.get(`/article?expand=body&title=${query}`);
      // setResult(res);
      navigate("/search", { state: { result: res } }); // Navigate to SearchPage with results
    } catch (error) {
      console.error("Error fetching query:", error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    if (query.trim()) {
      fetchData();
    }
  }, [query, language]);

  return (
    <div className={styles.searchBox}>
      <input
        className={styles.input}
        type="text"
        value={query}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder= {t("search")}
      />
      <FaSearch className={styles.searchIcon} onClick={handleSearch} />
    </div>
  );
}

export default SearchInput;

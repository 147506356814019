import React, { useState, useEffect } from "react";
import request from "../../config/app-global";
import { useLanguage } from "../../context/LanguageContext"; 
import { useTranslation} from "react-i18next";
import styles from "./LearnedObjects.module.scss"

//components
import StatisticsCard from "../statisticsCard/StatisticsCard"
import Container from "../globals/container/Container";

function LearnedObjects (){

    const {t} = useTranslation()
    const { language } = useLanguage(); 
    const [objects, setObjects] =useState([])

    useEffect(() => {
        const fetchArticl = async () => {
            try {
              const res = await request.get("/statistic-category");
              setObjects(res);
            } catch (error) {
              console.error("Error fetching menus:", error);
            }
        };
        fetchArticl();
    }, [language]); 



    return(
        <div className={styles.learnedSection}>
            <Container>
                <h2 className={styles.title}>{t("learnedObj")}</h2>
                <div className={styles.cardWrapper}>
                    {
                        objects?.map((el, idx)=>(
                            <StatisticsCard key={idx} id={el?.id} number={el?.count ? el?.count : "00"} title={el?.name[language]}/>
                        ))
                    }
                </div>
            </Container>

        </div>
    )
}
export default LearnedObjects;
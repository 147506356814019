import React from "react";
import styles from "./Navbar.module.scss";
// import request from "../../config/app-global";
import useMenuData from "../../config/menu";
import { useLanguage } from "../../context/LanguageContext"; 

// images
import Logo from "../../assets/svg/logo.png";
// icons
import { IoIosArrowDown } from "react-icons/io";
// components
import Container from "../globals/container/Container";
import { Link } from "react-router-dom";

function Navbar() {
  const menus = useMenuData(); // Menyularni olish
  const { language } = useLanguage(); // Use the context




  return (
    <>
      <Container>
        <div className={styles.navBox}>
          <Link to="/"><img className={styles.logo} src={Logo} alt="Logo" /></Link>
          <div className={styles.navWrapper}>
            <ul className={styles.navList}>
              {menus?.map((item, idx) => (
                <li className={styles.navItem} tabIndex={idx} key={item?.id}>
                  {item?.menus?.length ? (
                    <IoIosArrowDown className={styles.arrowIcon} />
                  ) : ( "" )
                  }
                  <Link to={item?.item} className={styles.navLink}>
                    {item?.name[language]}
                  </Link>
                  <ul className={styles.dropdown}>
                    {item?.menus?.length
                      ? item?.menus?.map((link, idx) => (
                          <li tabIndex={idx} key={link?.id}>
                            <Link to={`/${link?.type?.label}/${link?.item}`}>{link?.name[language]}</Link>
                          </li>
                        ))
                      : null}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Navbar;








import React, { useState, useEffect } from "react";
import styles from "./MobileMenu.module.scss";
import useMenuData from "../../config/menu";
import { useLanguage } from "../../context/LanguageContext"; 



//icons
import { IoIosArrowDown } from "react-icons/io";


function MobileMenu (){

    const [dropdownStates, setDropdownStates] = useState([]);
    const menus = useMenuData(); // Menyularni olish
    const { language } = useLanguage(); 

    useEffect(() => {
        // Menyu olishda dropdownStates ni o'rnatish
        setDropdownStates(Array(menus.length).fill(false));
    }, [menus]); 


    const handleDropdownToggle = (index) => {
        setDropdownStates((prevState) => {
            const newState = [...prevState];
            newState[index] = !newState[index]; // Tanlangan indeksni o'zgartirish
            return newState;
        });
    };

    return(
        <div className={styles.navWrapper}>
            <ul className={styles.navList}>

                {menus?.map((item, idx) => (
                <li className={styles.navItem} tabIndex={idx} key={item?.id}>
                  {item?.menus?.length ? (
                    <IoIosArrowDown className={`${styles.arrowIcon} ${dropdownStates[idx] ? styles.rotate : ""}`} />
                  ) : (
                    ""
                  )}
                <a
                    className={styles.navLink}
                    href={item?.item}
                    onClick={(e) => {
                    e.preventDefault(); 
                    handleDropdownToggle(idx);
                    }}
                >
                    {item?.name?.[language]}
                </a>
                {
                    <ul
                    className={`${styles.dropdown} ${
                        dropdownStates[idx] ? styles.open : ""
                    }`}
                    >
                    {item?.menus?.map((link, idx) => (
                        <li key={idx}>
                            <a href={`/${link?.type?.label}/${link?.item}`}>{link?.name?.[language]}</a>                      
                        </li>
                    ))}
                    </ul>
                }
                </li>
            ))}

            </ul>
        </div>
    )
}
export default MobileMenu;
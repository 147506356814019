import React, { useState, useEffect } from "react";
import request from "../../config/app-global";
import { useLanguage } from "../../context/LanguageContext"; 
import { useTranslation} from "react-i18next";
import styles from "./Partners.module.scss"
import "pure-react-carousel/dist/react-carousel.es.css";
import {
    CarouselProvider,
    Slider,
    Slide,
    ButtonBack,
    ButtonNext,
  } from "pure-react-carousel";

//component
import Container from "../globals/container/Container"
import PartnersCard from "../partnersCard/PartnersCard"

//images
import { FaArrowRight, FaArrowLeft } from "react-icons/fa6";

function Partners (){

    const {t} = useTranslation()
    const { language } = useLanguage(); 
    const [partners, setPartners] =useState([])
    const [visibleSlidess, setVisibleSlidess] = useState(4)

    useEffect(() => {
        const fetchArticl = async () => {
            try {
              const res = await request.get("/service/list");
              setPartners(res);
            } catch (error) {
              console.error("Error fetching menus:", error);
            }
        };
        fetchArticl();
    }, [language]); 


    useEffect(() => {
        const updateSlideHeight = () => {
          const width = window.innerWidth;
          if (width >= 1024) {
            setVisibleSlidess(4); // Desktop
          } else if (width >= 768) {
            setVisibleSlidess(2); // Tablet
          } else {
            setVisibleSlidess(1); // Mobile
          }
        };
    
        updateSlideHeight(); // Set initial height
        window.addEventListener('resize', updateSlideHeight); // Update height on resize
    
        return () => window.removeEventListener('resize', updateSlideHeight);
      }, []);


    return(
        <Container>
            <div className={styles.PartnerSection}>
                <h2 className={styles.title}>{t("portal")}</h2>
                <div className={styles.cardWrapper}>
                <CarouselProvider
                  naturalSlideWidth={100}
                  naturalSlideHeight={65}
                  totalSlides={partners?.length}
                  visibleSlides={visibleSlidess} 
                  infinite={true}
                  loop={true}
                  isPlaying={true}
                  interval={2000} 
                >
                    <div className={styles.carouselContainer}>
                      {/* <ButtonBack className={styles.intro__prewBtn}>
                          <FaArrowLeft/>
                      </ButtonBack>
                      <ButtonNext className={styles.intro__nextBtn}>
                          <FaArrowRight/>
                      </ButtonNext> */}

                      <Slider className={styles.sliderr}>
                          {
                            partners?.map((el, idx) => (
                                <Slide key={idx} className={styles.slidee}>
                                    <PartnersCard link={el?.url} text={el?.title[language]} photo={el?.photo} />
                                </Slide>
                            ))
                          }
                      </Slider>
                    </div>
                </CarouselProvider>
                </div>
            </div>
        </Container>
    )
}

export default Partners;
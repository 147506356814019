import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import request from "../../config/app-global";
import styles from "./Cripple.module.scss";
import { FaVolumeUp, FaEye  } from "react-icons/fa";
import { MdImageNotSupported } from "react-icons/md";
import Container from "../../components/globals/container/Container"

// import fetchAudio from '../../config/muxlisa';

function CrippleBox() {

  const { t } = useTranslation();

  const [fontSizeIncrement, setFontSizeIncrement] = useState(0);
  const [originalFontSizes, setOriginalFontSizes] = useState({});
  const [isFontSizeChanged, setIsFontSizeChanged] = useState(false);


  useEffect(() => {
    const elements = document.querySelectorAll("p, span, a");
    const originalSizes = {};
    elements.forEach(element => {
      originalSizes[element] = parseFloat(window.getComputedStyle(element).fontSize);
    });
    setOriginalFontSizes(originalSizes);
  }, []);

  useEffect(() => {
    const elements = document.querySelectorAll("p, span, i, a, b, li, h2, h3, h4, h5, h6");
    elements.forEach(element => {
      const originalFontSize = originalFontSizes[element];
      if (isFontSizeChanged && originalFontSize !== undefined) {
        const newFontSize = originalFontSize + fontSizeIncrement;
        element.style.fontSize = `${newFontSize}px`;
      } else {
        element.style.fontSize = ""; 
      }
    });
  }, [fontSizeIncrement, isFontSizeChanged, originalFontSizes]);

  const changeFontSize = (increment) => {
    setFontSizeIncrement(0);
    setTimeout(() => {
      setFontSizeIncrement(increment);
      setIsFontSizeChanged(true);
    }, 0);
  };

  const resetFontSize = () => {
    setFontSizeIncrement(0);
    setIsFontSizeChanged(false);
  };
  
  
  
  





  //tema o'zgartirish
  const changeTheme = (theme) => {
    if (theme === "default") {
      document.documentElement.removeAttribute("data-theme");
    } else {
      document.documentElement.setAttribute("data-theme", theme);
    }
  };

 
 //rasmlarni hide qilish uchun funksiya
 const [isImagesHidden, setIsImagesHidden] = useState(false);

 const handleHideImages = () => {
  // barcha img elementlarini oling
  const images = document.querySelectorAll('img');
  images.forEach((img) => {
    // asl URL'ni saqlang
    if (!img.dataset.originalSrc) {
      img.dataset.originalSrc = img.src; // asl URL'ni saqlash
    }
    img.src = 'https://ijtimoiyinspeksiya.uz/storage/source/dark-gray-color-solid-background-1920x1080.png?_t=1726065070';
  });

  // background-image'larni o'zgartirish
  const elementsWithBackground = document.querySelectorAll('*');
  elementsWithBackground.forEach((el) => {
    const bgImage = window.getComputedStyle(el).backgroundImage;
    if (bgImage && bgImage !== 'none') {
      // asl background image'ni saqlang
      if (!el.dataset.originalBgImage) {
        el.dataset.originalBgImage = bgImage; // asl background image'ni saqlash
      }
      el.style.backgroundImage = 'url(https://ijtimoiyinspeksiya.uz/storage/source/dark-gray-color-solid-background-1920x1080.png?_t=1726065070)';
    }
  });

  setIsImagesHidden(true);
};

const handleShowImages = () => {
  // asl img URL'larini tiklash
  const images = document.querySelectorAll('img');
  images.forEach((img) => {
    if (img.dataset.originalSrc) {
      img.src = img.dataset.originalSrc; // asl URL'ni tiklash
      delete img.dataset.originalSrc; // ma'lumotni o'chirish
    }
  });

  // background-image'larni tiklash
  const elementsWithBackground = document.querySelectorAll('*');
  elementsWithBackground.forEach((el) => {
    if (el.dataset.originalBgImage) {
      el.style.backgroundImage = el.dataset.originalBgImage; // asl background image'ni tiklash
      delete el.dataset.originalBgImage; // ma'lumotni o'chirish
    }
  });

  setIsImagesHidden(false);
};

  

  
  
  
  

  

  //ovoz uchun functsiya
  const [clicked, setClicked] = useState(false)
  const [selectedText, setSelectedText] = useState('');
  const [audioUrl, setAudioUrl] = useState(null);


  //ovoz tugmasi bosilishi
  const handleSoundButton = ()=>{
    setClicked(!clicked)
  }

// Matn tanlash hodisasini kuzatish
useEffect(() => {
  const handleSelection = () => {
    const selected = window.getSelection().toString();
    if (selected) {
      setSelectedText(selected);
    } 
  };
  
  document.addEventListener('mouseup', handleSelection);
  return () => {
    document.removeEventListener('mouseup', handleSelection);
  };
}, []);

// Tanlangan matn asosida API so'rovi

  useEffect(() => {
    if(clicked) {
      const fetchAudio = async () => {
        if (selectedText) {
          try {
            const res = await request.get(`/speak/read2?text=${encodeURIComponent(selectedText)}&speaker_id=0`);
            const audioUrl = res.file; // API dan olingan audio URL
            setAudioUrl(audioUrl);
    
          } catch (error) {
            console.error(error);
          }
        } else {
          setAudioUrl(null); // Tanlangan matn bo'lmasa, audio URL ni tozalash
        }
      };
      fetchAudio();
    }
  }, [selectedText]);


/////////////////////////////////////////////////////////////////////




  // const [isReading, setIsReading] = useState(false);

  // const handleReadPage = () => {
  //   if (isReading) {
  //     window.speechSynthesis.cancel();
  //   }
  //   setIsReading((prev) => !prev); 
  // };

  // useEffect(() => {
  //   const handleSelection = () => {
  //     if (isReading) {
  //       const selectedText = window.getSelection().toString();
  //       if (selectedText) {
  //         const utterance = new SpeechSynthesisUtterance(selectedText);
  //         utterance.lang = 'uz-UZ'; 
  //         window.speechSynthesis.speak(utterance);
  //       }
  //     }
  //   };
  //   document.addEventListener('mouseup', handleSelection);
  //   return () => {
  //     document.removeEventListener('mouseup', handleSelection);
  //   };
  // }, [isReading]);


  // const handleZoom = (scale) => {
  //   // Sahifadagi barcha matn elementlarini tanlash
  //   const textElements = document.querySelectorAll('p, h1, h2, h3, h4, h5, h6, span, a');
    
  //   // Tanlangan matn elementlariga zoom o'rnatish
  //   textElements.forEach((element) => {
  //     element.style.transform = `scale(${scale})`;
  //     element.style.transformOrigin = 'left top'; // Zoom qayerdan boshlanishini belgilash
  //   });
  // };
  


  return (
    <div className={styles.crippleBox}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.fontBox}>
            <p>{t("shrift")}</p>
            <button className={styles.fontBtn0} onClick={resetFontSize}>A</button>
            <button className={styles.fontBtn1} onClick={() => changeFontSize(3)}>A</button>
            <button className={styles.fontBtn2} onClick={() => changeFontSize(5)}>A</button>
            {/* <button className={styles.fontBtn0} onClick={() => handleZoom(1)}>A</button>
            <button className={styles.fontBtn1} onClick={() => handleZoom(1.1)}>A</button>
            <button className={styles.fontBtn2} onClick={() => handleZoom(1.2)}>A</button> */}
          </div>


          <div className={styles.colorBox}>
            <p>{t("color")}</p>
            <button id="white" onClick={() => changeTheme("default")}>r</button>
            <button id="black" onClick={() => changeTheme("dark")}>r</button>
            <button id="gray" onClick={() => changeTheme("gray")}>r</button>
          </div>

          <div className={styles.voiceBox}>
            <p>{t("noimage")}</p>
            <button onClick={handleHideImages}><MdImageNotSupported /></button>
          </div>
          <div className={styles.voiceBox}>
            <p>{t("normal")}</p>
            <button onClick={handleShowImages}><FaEye /></button>
          </div>
          <div className={styles.voiceBox}>
            <p>{t("loudly")}</p>
            <button onClick={handleSoundButton} style={{ backgroundColor: clicked ? "white" : "transparent" }}>
              <FaVolumeUp style={{ color: clicked ? "#406C3B" : "white" }}/>
              </button>
          </div>
          
          { clicked && ( audioUrl ? (
            <audio src={`data:audio/wav;base64,${audioUrl}`} controls autoPlay className={styles.audio}>
              {t("info3")}
            </audio>
          ) : (<p className={styles.audioText}>{t("changetext")}</p>))}
        </div>

      </Container>
    </div>
  );
}

export default CrippleBox;

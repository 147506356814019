import React, { createContext, useContext, useState } from 'react';

const StatisticsContext = createContext();

export const useStatistics = () => {
    return useContext(StatisticsContext);
};

export const StatisticsProvider = ({ children }) => {
    const [year, setYear] = useState('');
    const [quarter, setQuarter] = useState('');
    const [rowClicked, setRowClicked] = useState(false)

    const [loader1, setLoader1] = useState(null)

    return (
        <StatisticsContext.Provider value={{year, setYear, quarter, setQuarter, rowClicked, setRowClicked, loader1, setLoader1 }}>
            {children}
        </StatisticsContext.Provider>
    );
};

import { useState, useEffect } from 'react';
import request from "./app-global";

const useSocialData = () => {
    const [fetchedData, setFetchedData] = useState([]);
    const [social, setSocial] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await request.get(`/config`);
                setFetchedData(res);
            } catch (error) {
                console.error("Error fetching config data", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (fetchedData) {
            setSocial(fetchedData);
        }
    }, [fetchedData]);

    return social;
};

export default useSocialData;

import styles from "./LeftSidebar.module.scss";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import request from "../../config/app-global";
import { useLanguage } from "../../context/LanguageContext"; 
import { useTranslation } from "react-i18next";

import { IoIosArrowRoundForward } from "react-icons/io";

function LeftSidebar({ data, slug}) {
    const { language } = useLanguage(); 
    const { t } = useTranslation();

    return (
        <div className={styles.sidebar}>
            <h2 className={styles.title}>{t("adresses")}</h2>
            <Link className={styles.topLink} to={`/link/contact/list`}>{t("asosiy")} <IoIosArrowRoundForward /></Link>
            <ul className={styles.list}>
                {
                    data?.map((el, idx) => ( 
                        el?.soato === slug ?(
                        <li key={idx} className={styles.activeItem}>
                            <Link to={`/contact/${el?.soato}`}>{el?.name[language]}</Link>
                        </li>
                        ) : (
                            <li key={idx} >
                                <Link to={`/link/contact/${el?.soato}`}>{el?.name[language]}</Link>
                            </li>
                        )
                        
                    ))
                }
            </ul>
        </div>
    );
}

export default LeftSidebar;

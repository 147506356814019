import React from "react";
import { Link } from "react-router-dom";
import styles from "./StickyNavbar.module.scss";


import useMenuData from "../../config/menu";
import { useLanguage } from "../../context/LanguageContext"; 

//icons
import { IoIosArrowDown } from "react-icons/io";
//components
import Container from "../globals/container/Container"



function StickyNavbar () {

    const menus = useMenuData(); // Menyularni olish
    const { language } = useLanguage(); // Use the context

    return(
        <div className={styles.stickyNavbar}>
            <Container>
                <div className={styles.navWrapper}>
                    <ul className={styles.navList}>
                    {menus?.map((item, idx) => (
                        <li className={styles.navItem} tabIndex={idx} key={item?.id}>
                        {item?.menus?.length ? (
                            <IoIosArrowDown className={styles.arrowIcon} />
                        ) : ("")
                        }
                        <Link to={item?.item} className={styles.navLink}>
                            {item?.name[language]}
                        </Link>
                        <ul className={styles.dropdown}>
                            {item?.menus?.length
                            ? item?.menus?.map((link, idx) => (
                                <li tabIndex={idx} key={link?.id}>
                                    <Link to={`/${link?.type?.label}/${link?.item}`}>{link?.name[language]}</Link>
                                </li>
                                ))
                            : null}
                        </ul>
                        </li>
                    ))}
                    </ul>
                </div>
            </Container>        
        </div>
    )
}

export default StickyNavbar;
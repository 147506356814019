
import React, { useState, useEffect } from "react";
import request from "../../config/app-global";
import { useLanguage } from "../../context/LanguageContext"; 
import { useTranslation } from "react-i18next";

import styles from "./Carusel.module.scss"
import "pure-react-carousel/dist/react-carousel.es.css";

// components
import Container from "../globals/container/Container";


import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";

//images
import { FaArrowRight, FaArrowLeft } from "react-icons/fa6";




function CaruselBanner() {
  const { t } = useTranslation();
  const [slider, setSlider] = useState([])
  const { language } = useLanguage();
  const [slideHeight, setSlideHeight] = useState(50);

  const [currentSlide, setCurrentSlide] = useState(0);
  

  useEffect(() => {
      const fetchSlider = async () => {
          try {
          const res = await request.get("/slider/list");
          setSlider(res);
          } catch (error) {
          console.error("Error fetching menus:", error);
          }
      };
      fetchSlider();
  }, [language]);

  useEffect(() => {
    const updateSlideHeight = () => {
      const width = window.innerWidth;
      if (width >= 1024) {
        setSlideHeight(50);
      } else if (width >= 768) {
        setSlideHeight(65);
      } else {
        setSlideHeight(80);
      }
    };

    updateSlideHeight();
    window.addEventListener('resize', updateSlideHeight); 

    return () => window.removeEventListener('resize', updateSlideHeight);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slider?.length);
    }, 8000);
  
    return () => clearInterval(interval); 
  }, [slider?.length]);


  return (
    <>
      <div className={styles.introSection}>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={slideHeight}
          totalSlides={slider?.length}
          currentSlide={currentSlide}
          loop={true}
        >
          <Slider className={styles.intro__imgWrapper}>
            {
              slider?.map((el, idx)=>(
                <Slide index={idx} key={idx} className={styles.intro__img} style={{ backgroundImage: `url(${el?.photo})` }}>
                  <Container>
                      <div className={styles.textWrapper}>
                          <h3>{el?.title[language]}</h3>
                          <div className={styles.buttonsWrapper}>
                            <a href={`${el?.url}`} className={styles.button}>{t("moreBtn")}</a> 
                            <div className={styles.arrowWrapper}>
                              <ButtonBack className={styles.intro__prewBtn}>
                                <FaArrowLeft color="white" size={30}/>
                              </ButtonBack>
                              <ButtonNext className={styles.intro__nextBtn}>
                                <FaArrowRight color="white" size={30}/>
                              </ButtonNext>
                            </div>
                          </div>
                      </div>
                  </Container>
                </Slide>
              ))
            }
          </Slider>
        </CarouselProvider>

        
      </div>
    </>
  );
}

export default CaruselBanner;

import React, { useState, useEffect } from "react";
import request from "../config/app-global";
import { useLanguage } from "../context/LanguageContext"; 

import styles from "./pagestyles/SiteMap.module.scss";

//components
import Container from "../components/globals/container/Container";
import Layout from "../layouts/layoutView/LayoutView";




function SiteMap (){
    const [menus, setMenus] = useState([]);
    const { language } = useLanguage(); // Use the context
  
    useEffect(() => {
      const fetchMenus = async () => {
          try {
            const res = await request.get("/menu");
            setMenus(res);
          } catch (error) {
            console.error("Error fetching menus:", error);
          }
      };
      fetchMenus();
    }, [language]); // Re-fetch data when the language changes



    return(
        <Layout>
            <Container>
                <div className={styles.contentWrapper}>
                    <div className={styles.navWrapper}>
                        <ul className={styles.navList}>
                        {menus?.map((item, idx) => (
                            <li className={styles.navItem} tabIndex={idx} key={item?.id}>
                        
                            <a href={item?.item} className={styles.navLink}>
                                {item?.name[language]}
                            </a>
                            <ul className={styles.dropdown}>
                                {item?.menus?.length
                                ? item?.menus?.map((link, idx) => (
                                    <li tabIndex={idx} key={link?.id}>
                                        <a href={`/${link?.type?.label}/${link?.item}`}>{link?.name[language]}</a>
                                    </li>
                                    ))
                                : null}
                            </ul>
                            </li>
                        ))}
                        </ul>
                    </div>
                </div>
            </Container>
        </Layout>

    )
}
export default SiteMap;
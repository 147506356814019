import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import request from "../../config/app-global";
import { useLanguage } from "../../context/LanguageContext"; 
import { useTranslation } from "react-i18next";
import styles from "./Breadcrump.module.scss";

function Breadcrump({ slug }) {
    const { t } = useTranslation();
    const [menu, setMenu] = useState();
    const { language } = useLanguage();
    const location = useLocation();
    const { pathname } = location;

    // Determine the slug to use
    let actualSlug;

    const segments = pathname.split('/').filter(Boolean);
    const linkIndex = segments.indexOf('link');

    if (linkIndex !== -1 && segments[linkIndex + 1]) {
        actualSlug = segments.slice(linkIndex + 1).join('/');
    } else {
        actualSlug = slug;
    } 

    // console.log("menu", menu, "slug", actualSlug);
    
    useEffect(() => {
        const fetchMenu = async () => {
            try {
                const res = await request.get(`/menu/sidebar?item=${actualSlug}`);
                setMenu(res); 
            } catch (error) {
                console.error("Error fetching menus:", error);
            }
        };

        if (actualSlug) {
            fetchMenu();
        }
    }, [language, actualSlug]);



    return (
        <div className={styles.wrapper}>
            {menu ? (
                <span>{t('home')} / {menu?.parent?.name?.[language]} / {menu?.name?.[language]}</span>
            ) : ("")}
        </div>
    );
}

export default Breadcrump;

import React from 'react';
import Chart from 'react-apexcharts';
import styles from "./ChartBox.module.scss";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../context/LanguageContext";



function ChartBox({data}){

    const { language } = useLanguage(); 

    const years = data?.[0]?.count ? data[0].count.map((item, idx) => item?.year) : [];

    const options = {
        chart: {
            type: 'bar'
        },
        xaxis: {
            categories: years
        },
        yaxis: {
            show: false 
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                endingShape: 'rounded',
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'all',
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: true
        },
        colors: ['#689B63', '#FA4655', '#5CCDCD', '#5F4CD2', '#FFA41B'],
        grid: {
            
            padding: {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5
            }
        },
        tooltip: {
            shared: false,
            intersect: true,
        }
    };

    const series = data ?  data?.map((item)=>(
        {
            name: item?.name?.[language],
            data: item?.count?.map((el)=> el?.sum)
        }
    )) : []

    return (
        <div className={styles.section}>
             <Chart options={options} series={series} type="bar" width="100%" height="400" />
        </div>
    )
}
export default ChartBox;


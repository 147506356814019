import React, { useState} from "react";
import styles from "./ServiceRow.module.scss";
import Regions from "../regions/Regions.js";
import { useLanguage } from "../../context/LanguageContext"; 
import { useStatistics } from '../../context/StatisticsContext';
import { useTranslation } from "react-i18next";


function ServiceRow({ data }) {
  const { setRowClicked, loader1, setLoader1} = useStatistics();
  const [visibleRow, setVisibleRow] = useState(null);
  const [isLoaderSet, setIsLoaderSet] = useState();
  const { language } = useLanguage(); 
  const { t } = useTranslation();


  
  const handleRowClick = (idx) => {
    setRowClicked(true);
    setIsLoaderSet(idx)
    isLoaderSet === idx ? setLoader1(null) : setLoader1(idx);
    setVisibleRow(visibleRow === idx ? null : idx);
  };


  return (
    <>
      {
        data?.map((item, idx) => (
          <div key={idx}>
            <div
              className={`${styles.row} ${idx % 2 ? styles.bgWhite : ""}`}
              onClick={() => handleRowClick(idx)}
            >
              <p className={styles.number}>{item?.count} {t("pcs")}</p>
              <p className={styles.title}>{item?.name?.[language]}</p>
              {loader1 === idx && (
                <div className={styles.loaderWrapper}><div className={styles.loader}></div></div>
              )}
            </div>

            {visibleRow === idx && (
              <div className={`${styles.regionsContainer} ${visibleRow === idx ? styles.expand : styles.collapse}`}>
                <Regions categoryId={item?.id} />
              </div>
            )}
          </div>
        ))
      }

    </>
  );
}

export default ServiceRow;





























// import React, { useState, useEffect } from "react";
// import request from "../../config/app-global";
// import { useLanguage } from "../../context/LanguageContext"; 
// import { useParams } from "react-router-dom";
// import styles from "./ServiceRow.module.scss";

// //components
// import Regions from "../regions/Regions.js";

// function ServiceRow({data, year, quarter}) {

//   const { language } = useLanguage(); 
//   const [visibleRow, setVisibleRow] = useState(null);


//   const handleRowClick = (idx) => {
//     setVisibleRow(visibleRow === idx ? null : idx);
//   };


//   return (
//     <>
//       {
//       data?.map((item, idx) => (
//         <div key={idx} >
//           <div className={`${styles.row} ${idx % 2 ? styles.bgWhite : ""}`} onClick={() => handleRowClick(idx)}>
//             <p className={styles.number}>{item?.count} ta</p>
//             <p className={styles.title}>{item?.name?.[language]}</p>
//           </div>
          
//           <div className={`${styles.regionsContainer} ${visibleRow === idx ? styles.expand : styles.collapse}`}>
//             <Regions year={year} quarter={quarter} categoryId={item?.id}/>
//           </div>
//         </div>
//       ))}
//     </>
//   );
// }

// export default ServiceRow;


import React from "react";

import styles from "./HeaderView.module.scss"


// components
import TopBar from "../topBar/TopBar";
import NavbarView from "../navbarView/NavbarView";




function HeaderView(){

    return(
        <div className={styles.header} >
            <TopBar/>
            <NavbarView/>
        </div>

    )
}
export default HeaderView;
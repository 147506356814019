import globalStyles from "../../../styles/globals.module.scss"


function Container ({ children }){
    return (
        <div className={globalStyles.container}>
            {children}
        </div>
    )
}
export default Container;
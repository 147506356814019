
import styles from "./NewsCard.module.scss";

// images
// import img2 from "../../assets/images/news.png"

// icons
import { FaRegCalendarAlt, FaEye } from "react-icons/fa";




function NewsCard ({title, info, img, date, view, slug}){
    return(
        <>
        <div className={styles.card}>
            <img className={styles.img} src={img}/>
            <div className={styles.infoWrapper}>
                <div className={styles.dateRow}>
                 <p className={styles.date}><FaRegCalendarAlt />{date}</p>
                 <p className={styles.date}><FaEye />{view} </p>
                </div>
                <a href={`/article/${slug}`} className={styles.title}>{title}</a>
                <p className={styles.info}>{info}</p>
            </div>
        </div>
        </>
    )
}

export default NewsCard;
import React, { useState, useEffect } from "react";
import request from "../config/app-global";
import { useLanguage } from "../context/LanguageContext"; 
import { useParams } from "react-router-dom";
import styles from "./pagestyles/News.module.scss"

//components
import LayoutView from "../layouts/layoutView/LayoutView"
import NewsAll from "../components/newsAll/NewsAll"
import Container from "../components/globals/container/Container";
import RightSidebar from "../components/rightSidebar/RightSidebar";
import Breadcrump from "../components/breadcrump/Breadcrump";



function News (){
    const { slug } = useParams();
    const [fetchedNews, setFetchedNews] = useState([]);
    const [news, setNews] = useState([])
    const { language } = useLanguage(); 

    useEffect(() => {
        const fetchArticl = async () => {
            try {
              const res = await request.get(`/article?expand=body&category=${slug}`);
              setFetchedNews(res);
            } catch (error) {
              console.error("Error fetching menus:", error);
            }
        };
        fetchArticl();
    }, [language, slug]); 

    useEffect(() => {
        if (fetchedNews) {
            setNews(fetchedNews);
        }
    }, [fetchedNews]);

    console.log("slugnews", slug)

   
    
    return(
        <>
            <LayoutView>
                <div className={styles.box}>
                    <Container>
                        <Breadcrump slug={slug}/>
                        <div className={styles.contentWrapper}>
                            <NewsAll news={news ? news : null}/>
                            <RightSidebar slug={slug}/>
                        </div>
                    </Container>
                </div>
            </LayoutView>
        </>
    )
}
export default News;

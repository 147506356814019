import styles from "./PartnersCard.module.scss"

//images


//components


function PartnersCard ( {link, text, photo}) {
    return (
        <div className={styles.cardwarapper}>
            <div className={styles.card} style={{ backgroundImage: `url(${photo})` }}>
            <a href={link} target="_blank" rel="noopener noreferrer" className={styles.newsLink}>{text}</a>
            </div>
        </div>
    )
}
export default PartnersCard;
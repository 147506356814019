
import styles from "./NewsCard2.module.scss";
import { Link } from "react-router-dom";

// images
import img2 from "../../assets/images/news.png"

// icons
import { FaRegCalendarAlt, FaEye } from "react-icons/fa";
import { IoIosArrowRoundForward } from "react-icons/io";




function NewsCard2 ({title, img, date, slug, view}){
    return(
        <>
        <div className={styles.card}>
            <img className={styles.img} src={img}/>
            <div className={styles.infoWrapper}>
                <Link to={`/article/${slug}`} className={styles.title}>{title}</Link>
                {/* <p className={styles.info}></p> */}
                <div className={styles.dateRow}>
                 <div>
                    <p className={styles.date}><FaRegCalendarAlt />{date}</p>
                    <p className={styles.date}><FaEye />{view} </p>
                 </div>
                 <Link to={`/article/${slug}`}><IoIosArrowRoundForward className={styles.arrow}/></Link>
                </div>
            </div>
        </div>
        </>
    )
}

export default NewsCard2;
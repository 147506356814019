
import styles from "./LayoutView.module.scss"


// components
import Footer from "../../components/footer/Footer";
import HeaderView from "../../components/headerView/HeaderView";

import { IoIosArrowUp } from "react-icons/io";



function Layout({ children }) {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  
  return (
    <div className={styles.layoutWrapper}>
      <HeaderView />
     
      {children}

      <Footer/>
      <button className={styles.scrollToTop} onClick={handleScrollToTop}><IoIosArrowUp /></button>
    </div>
  );
}

export default Layout;
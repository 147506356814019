import React from 'react';
import styles from "./Social.module.scss";

// icons
import { FaYoutube, FaFacebookF, FaInstagram, FaTelegramPlane, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

function Social({ ButtonText, buttonLink }) {
    const currentUrl = window.location.href;

    const handleShare = (platform) => {
        let shareUrl = '';
        switch (platform) {
            case 'Facebook':
                shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`;
                break;
            case 'Twitter':
                shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`;
                break;
            case 'LinkedIn':
                shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(currentUrl)}`;
                break;
            case 'Instagram':
                shareUrl = `https://www.instagram.com/?url=${encodeURIComponent(currentUrl)}`; 
                break;
            case 'Telegram':
                shareUrl = `https://t.me/share/url?url=${encodeURIComponent(currentUrl)}`;
                break;
            case 'YouTube':
                shareUrl = `https://www.youtube.com/share?url=${encodeURIComponent(currentUrl)}`; 
                break;
            default:
                break;
        }
        window.open(shareUrl, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className={styles.box}>
            <div className={styles.socialBox}>
                {/* {<a href="#" onClick={() => handleShare('YouTube')}><FaYoutube /></a>} */}
                {<a href="#" onClick={() => handleShare('Facebook')}><FaFacebookF /></a>}
                {<a href="#" onClick={() => handleShare('Instagram')}><FaInstagram /></a>}
                {<a href="#" onClick={() => handleShare('Telegram')}><FaTelegramPlane /></a>}
                {<a href="#" onClick={() => handleShare('Twitter')}><FaXTwitter /></a>}
                {<a href="#" onClick={() => handleShare('LinkedIn')}><FaLinkedinIn /></a>}
            </div>
            {ButtonText && (<a href={buttonLink ? buttonLink : "/"} className={styles.socialText}>{ButtonText}</a>)}
        </div>
    );
}

export default Social;

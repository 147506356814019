import styles from "./pagestyles/Documents.module.scss";
import React, { useState, useEffect } from "react";
import request from "../config/app-global";
import { useLanguage } from "../context/LanguageContext";
import { useParams } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

//components
import LayoutView from "../layouts/layoutView/LayoutView";
import Container from "../components/globals/container/Container";
import RightSidebar from "../components/rightSidebar/RightSidebar";
import DocumentCard from "../components/documentCard/DocumentCard";
import Breadcrump from "../components/breadcrump/Breadcrump";

function Documents() {
    const { slug } = useParams();
    const [documents, setDocuments] = useState([]);
    const [fetchedDocuments, setFetchedDocuments] = useState([]);
    const { language } = useLanguage();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await request.get(`/document?document_category_id=${slug}&expand=files`);
                setFetchedDocuments(res);
            } catch (error) {
                console.error("Error fetching documents:", error);
            }
        };

        fetchData();
    }, [language, slug]);

    useEffect(() => {
        if (fetchedDocuments.length > 0) {
            setDocuments(fetchedDocuments);
        }
    }, [fetchedDocuments]);

    const sectionTitle = documents.length > 0 ? documents[0]?.category?.name[language] : "";

    return (
        <HelmetProvider>
            <LayoutView>
                <Helmet>
                  <title>Normativ hujjatlar</title>
                </Helmet>
                <div className={styles.section}>
                    <Container>
                        <Breadcrump slug={slug}/>
                        <div className={styles.contentWrapper}>
                            <div className={styles.cardWrapper}>
                                <h2 className={styles.sectionTitle}>{sectionTitle}</h2>
                                <div className={styles.documentCardWrapper}>
                                    {documents.map((el, idx) => (
                                        <DocumentCard 
                                            key={idx} 
                                            title={el?.name[language]}
                                            url={el?.files}
                                        />
                                    ))}
                                </div>
                            </div>
                            <RightSidebar slug={slug} />
                        </div>
                    </Container>
                </div>
            </LayoutView>
        </HelmetProvider>
    );
}

export default Documents;

import styles from "./Statistic.module.scss"
import Chart from 'react-apexcharts'
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../context/LanguageContext"; 

//components
import InfoCard from '../infoCard/InfoCard';

//images
// import Home from "../../assets/images/statistic-home.png";
// import Oldest from "../../assets/images/statistik-old.png";
// import Children from "../../assets/images/statistik-friend.png";
// import Cripple from "../../assets/images/statistic-aa.png";


function StatisticBox ({data}) {
  const { language } = useLanguage(); 
  const { t } = useTranslation();


    const options = {
        chart: {
            type: 'donut'
        },
        labels: data ? data?.map((item) => item?.name?.[language]) : [],
        dataLabels: {
            enabled: true
        },
        legend: {
            show: false 
        },
        colors: ['#5F4CD2', '#F95760', '#FFA41B', '#5CCDCD']
    };

    const series = data ? data?.map((item) => item?.count) : [];

    return (
        <div className={styles.section}>
            <h2 className={styles.title}>{t("profilactic")}</h2>
            <div className={styles.contentBox}>
                <Chart className={styles.roundchart} options={options} series={series} type="donut"width='270px' height='270px' />
                <div className={styles.infoCards}>
                  {
                    data?.map((item, idx)=>(
                      <InfoCard 
                        key={idx}
                        number={item?.count ? item?.count : '0'}
                        title={item?.name?.[language]}
                        icon={item?.icon}
                      />
                    ))
                  }
                </div> 
            </div>
        </div>
    )
}
export default StatisticBox;
import styles from "./News.module.scss";
import React, { useState, useEffect } from "react";
import request from "../../config/app-global";
import { useLanguage } from "../../context/LanguageContext"; 
import { useParams } from "react-router-dom";
// import {stripHtmlTags} from "../../helpers/stripHtmlTags"

// components
import LayoutView from "../../layouts/layoutView/LayoutView";
import Container from "../../components/globals/container/Container";
import RightSidebar from "../../components/rightSidebar/RightSidebar";
import ContentBox from "../../components/contentBox/ContentBox";
import Breadcrump from "../../components/breadcrump/Breadcrump";

function PostView() {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [fetchedPost, setFetchedPost] = useState(null); // Secondary state for fetched data
    const { language } = useLanguage(); 

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const res = await request.get(`/article/${slug}?expand=body`);
                setFetchedPost(res); // Store the fetched data in the secondary state
            } catch (error) {
                console.error("Error fetching article:", error);
            }
        };
        fetchArticle();
    }, [language, slug]);

    useEffect(() => {
        if (fetchedPost) {
            setPost(fetchedPost); 
        }
    }, [fetchedPost]);

    return (
        <LayoutView>
            <div className={styles.pageView}>
                <Container>          
                    <div className={styles.contentWrapper}>
                        {post ? (
                            <ContentBox 
                                title={post?.title && post?.title[language] ? post.title[language] : ""}
                                img={post?.thumbnail_path}
                                date={post?.published_at}
                                info={post?.body && post.body[language] ? post.body[language] : ""}
                                viewCount={post?.view_count}
                            />
                        ) : (
                            <div>Loading...</div>
                        )}
                        <RightSidebar slug={post?.slug}/>
                    </div>
                </Container>
            </div>
        </LayoutView>
    );
}

export default PostView;

import { Link } from "react-router-dom";
import styles from "./Statistics.module.scss"


function StatisticsCard ({number, title, id}){
    return(
        <Link to={`/statistics/${id}`} className={styles.cardWrapper}>
            <div className={styles.card}>
                <div className={styles.round}>
                    <h6>{number}</h6>
                </div>
                <p className={styles.title}>{title}</p>
            </div>
        </Link>
    )
}
export default StatisticsCard;
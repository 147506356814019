
import i18n from "i18next";
import request from "./config/app-global";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const savedLanguage = localStorage.getItem("language") || "luz";

async function loadResources() {
  try {
    const response = await request.get("/language");
    const translations = response; 

    Object.keys(translations).forEach((lang) => {
      i18n.addResourceBundle(lang, "translations", translations[lang], true, true);
    });

    i18n.changeLanguage(savedLanguage);

  } catch (error) {
    console.error("Tarjimalarni yuklashda xatolik:", error);
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: savedLanguage,
    fallbackLng: "luz",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
    },
  });

// Resurslarni yuklashni chaqirish
loadResources();

export default i18n;







//////////////////////////////////////////////////////////////////////////////////////////////////////





//tilni local malumot bilan ishlatish kodlari

// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import kuz from "./locales/kuz.json";
// import luz from "./locales/luz.json";
// import ru from "./locales/ru.json";
// import eng from "./locales/eng.json";

// // Retrieve language preference from localStorage
// const savedLanguage = localStorage.getItem("language");






// i18n
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     resources: {
//       luz: { translations: luz },
//       kuz: { translations: kuz },
//       ru: { translations: ru },
//       eng: { translations: eng },
//     },
//     lng: savedLanguage || "luz", // Use saved language or default to "luz"
//     fallbackLng: "luz",
//     debug: false,
//     ns: ["translations"],
//     defaultNS: "translations",
//     keySeparator: false,
//     interpolation: {
//       escapeValue: false,
//     },
//   });

// export default i18n;








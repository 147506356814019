import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';



// pages
import Home from "./pages/Home";
import News from "./pages/News.js";
import Documents from "./pages/Documents.js";
import Leadership from "./pages/Leadership";
import PageView from "./pages/pageView/[page].js";
import PostView from "./pages/news/[slug].js";
import ChartInfo from "./pages/ChartInfo.js";
import Contact from "./pages/Contact.js";
import Sitemap from "./pages/SiteMap.js";
import SearchPage from "./pages/SearchPage";
import ContactOnline from "./pages/ContactOnline.js";
import Statistics from "./pages/statistics/[slug].js";



function App() {
  
  return (
        <Suspense fallback={<div></div>}>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/news' element={<News />} />
              <Route path='/category/:slug' element={<News />} />
              <Route path='/document-category/:slug' element={<Documents />} />
              <Route path='/staff/:slug' element={<Leadership />} />
              <Route path='/article/:slug' element={<PostView />} />
              <Route path='/page/:slug' element={<PageView />} />
              <Route path='/page/tekshiruv-va-profilaktika-ishlari' element={<ChartInfo />} />
              <Route path='/page/oavda-chiqishlar' element={<ChartInfo />} />
              <Route path='/link/contact/:slug' element={<Contact />} />
              <Route path='/map' element={<Sitemap />} />
              <Route path='/search' element={<SearchPage />} />
              <Route path='/link/appeall' element={<ContactOnline />} />
              <Route path='/statistics/:slug' element={<Statistics />} />
            </Routes>
        </Suspense> 
  )
}

export default App;

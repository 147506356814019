import React, { useState, useEffect } from "react";
import request from "../../config/app-global";
import { useLanguage } from "../../context/LanguageContext"; 
import { useStatistics } from '../../context/StatisticsContext';
import { useTranslation } from "react-i18next";
import styles from "./Regions.module.scss";

//components
import Districts from "../districts/Districts.js";

function Regions({ categoryId }) {
  const { t } = useTranslation();
  const { language } = useLanguage(); 
  const { year, quarter, rowClicked, setLoader1} = useStatistics();
  
  const [visibleRow, setVisibleRow] = useState(null);
  const [info, setInfo] = useState({});
  
  useEffect(() => {
    if (!rowClicked) return;

    const fetchData = async () => {
      try {
        const url = `/statistic-data?category_id=${categoryId}&year=${year}&quarter_id=${quarter || ""}`;
        const res = await request.get(url);
        setInfo(res);
        setLoader1(null)
        // console.log("id", categoryId, "year", year, "quarter", quarter, "info", info)
      } catch (error) {
        console.error("Error fetching statistics region:", error);
      }
    };

    fetchData();
  }, [year, quarter, categoryId, language, rowClicked, setLoader1]);

  const handleRowClick = (idx) => {
    setVisibleRow(visibleRow === idx ? null : idx);
  };

  return (
    <div className={styles.regionWrapper}>
      <div className={styles.headWrapper}>
        <p className={styles.regionHead}>{t("regions")}</p>
        <p className={styles.numberHead}>{t("learn")}</p>
        <p className={styles.numberHead}>{t("shortcoming")}</p>
      </div>
      <div>   
        {Object.entries(info || {}).map((item, idx) => (
          <div key={idx}>
            <div
              className={`${styles.regionRow} ${idx % 2 ? styles.bgWhite : ""} ${visibleRow === idx ? styles.active : ""}`}
              onClick={() => handleRowClick(idx)}
            >
              <p className={styles.regionName}>{item?.[1]?.region_data?.region?.name?.[language]}</p>
              <p className={styles.regionNumber}>{item?.[1]?.region_data?.count?.learn_count} {t("pcs")}</p>
              <p className={styles.regionNumber2}>{item?.[1]?.region_data?.count?.found_flaw} {t("pcs")}</p>
            </div>
            {visibleRow === idx && (
              <div className={`${styles.regionsContainer} ${visibleRow === idx ? styles.expand : styles.collapse}`}>
                <Districts district={item?.[1]?.items} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Regions;

















// import React, { useState, useEffect, useRef } from "react";
// import request from "../../config/app-global";

// import { useLanguage } from "../../context/LanguageContext"; 

// import styles from "./Regions.module.scss";

// //components
// import Districts from "../districts/Districts.js"

// function Regions({ quarter, year, categoryId }) {
//   const { language } = useLanguage(); 
  
//   const [visibleRow, setVisibleRow] = useState(null);
//   const [info, setInfo] = useState({}); // Initialize with an empty object
//   const isFetching = useRef(false);

//   // console.log("asd", quarter, year, categoryId)

//   useEffect(() => {
//     if (isFetching.current) return;
    
//     isFetching.current = true;

//     // Fetch region data
//     const fetchData = async () => {
//       try {
//         const url = `/statistic-data?category_id=${categoryId}&year=${year}&quarter_id=${quarter || ""}`;
//         const res = await request.get(url);
//         setInfo(res);
//       } catch (error) {
//         console.error("Error fetching statistics region:", error);
//       } finally {
//         isFetching.current = false;
//       }
//     };

//     fetchData();
//   }, [year, quarter, categoryId, language]); 

//   const handleRowClick = (idx) => {
//     setVisibleRow(visibleRow === idx ? null : idx);
//   };

//   return (
//     <div className={styles.regionWrapper}>
//       <div className={styles.headWrapper}>
//         <p className={styles.regionHead}>Hududlar</p>
//         <p className={styles.numberHead}>O'rganishlar</p>
//         <p className={styles.numberHead}>Kamchiliklar</p>
//       </div>
//       <div>   
//         {Object.entries(info || {}).map((item, idx) => (
//           <div key={idx}>
//             <div
//               className={`${styles.regionRow} ${idx % 2 ? styles.bgWhite : ""} ${visibleRow === idx ? styles.active : ""}`}
//               onClick={() => handleRowClick(idx)}
//             >
//               <p className={styles.regionName}>{item?.[1]?.region_data?.region?.name?.[language]}</p>
//               <p className={styles.regionNumber}>{item?.[1]?.region_data?.count?.learn_count} ta</p>
//               <p className={styles.regionNumber2}>{item?.[1]?.region_data?.count?.found_flaw} ta</p>
//             </div>

//             <div
//               className={`${styles.regionsContainer} ${visibleRow === idx ? styles.expand : styles.collapse}`}
//             >
//               <Districts district={item?.[1]?.items} />
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default Regions;










































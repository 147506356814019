import React, { useState, useEffect } from "react";
import request from "../../config/app-global";
import { useLanguage } from "../../context/LanguageContext";
import { useParams } from "react-router-dom";
import styles from "./Page.module.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';

// components
import LayoutView from "../../layouts/layoutView/LayoutView";
import Container from "../../components/globals/container/Container";
import RightSidebar from "../../components/rightSidebar/RightSidebar";
import ContentBox from "../../components/contentBox/ContentBox";
import Breadcrump from "../../components/breadcrump/Breadcrump";

function PageView() {
    const { slug } = useParams();
    const [page, setPage] = useState(null); // Main state for the current data
    const [fetchedPage, setFetchedPage] = useState(null); // Secondary state for fetched data
    const { language } = useLanguage();

    useEffect(() => {
        // Fetch page data
        const fetchData = async () => {
            try {
                const res = await request.get(`/page/view?slug=${slug}&expand=body,photo,keywords,description,meta_title`);
                setFetchedPage(res);
            } catch (error) {
                console.error("Error fetching page:", error);
            }
        };
        
        fetchData();
    }, [language, slug]);

    useEffect(() => {
        if (fetchedPage) {
            setPage(fetchedPage); // Update the main state only when the fetched data is ready
        }
    }, [fetchedPage]);

    // Page meta titles
    const metaTitle = page?.meta_title;



    return (
        <HelmetProvider>
            <LayoutView>
                <Helmet>
                    <title>{page?.title?.[language]}</title>
                    <meta name="description" content={page?.description?.[language]} />
                    <meta name="keywords" content={metaTitle} />
                    <link rel="canonical" href={`https://ijtimoiyinpeksiya.uz/${page?.slug}`} />
                </Helmet>
                <div className={styles.pageView}>
                    <Container>
                        <Breadcrump slug={slug}/>
                        <div className={styles.contentWrapper}>
                            {page ? (
                                <ContentBox 
                                    title={page?.title && page?.title[language] ? page.title[language] : ""}
                                    img={page?.photo}
                                    info={page?.body && page?.body[language] ? page.body[language] : ""}
                                />
                            ) : (
                                <div className={styles.noData}></div>
                            )}
                            <RightSidebar slug={slug}/>
                        </div>
                    </Container>
                </div>
            </LayoutView>
        </HelmetProvider>
    );
}

export default PageView;

import request from "../config/app-global";
import styles from "./pagestyles/Statistics.module.scss";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

//components
import LayoutView from "../layouts/layoutView/LayoutView"
import Container from "../components/globals/container/Container"
import RightSidebar from "../components/rightSidebar/RightSidebar"
import StatisticBox from "../components/statisticBox/StatisticBox"
import ChartBox from "../components/chartBox/ChartBox"
import Breadcrump from "../components/breadcrump/Breadcrump";


function ChartInfo (){
    const location = useLocation();
    const { pathname } = location;
    const segments = pathname.split('/').pop();

    const [fetchedData, setFetchedData] = useState([]);
    const [info, setInfo] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
              const res = await request.get(`/statistic-category/chart`);
              setFetchedData(res);
            } catch (error) {
              console.error("Error fetching chart data", error);
            }
        };
        fetchData();
    }, [ ]); 

    useEffect(() => {
        if (fetchedData) {
            setInfo(fetchedData);
        }
    }, [fetchedData]);


    return (
        <HelmetProvider>
            <LayoutView>
                <Helmet>
                    <title>Tekshiruv va profilaktika ishlari</title>
                    {/* <meta name="description" content="" /> */}
                    {/* <meta name="keywords" content="statistika" /> */}
                    {/* <link rel="canonical" href={`https://ijtimoiyinpeksiya.uz/statistics/`}/> */}
                </Helmet>
                <div className={styles.StatisticsSection}>
                    <Container>
                        <Breadcrump slug={segments}/>
                        <div className={styles.sectionWrapper}>
                            <div className={styles.contentBox}>
                            <StatisticBox data={info?.items}/>
                            <ChartBox data={info?.chart}/>
                            </div>
                            <RightSidebar/>
                        </div>
                    </Container>
                </div>
            </LayoutView>
        </HelmetProvider>
    )
}

export default ChartInfo;
import React from "react";
import { useLanguage } from "../context/LanguageContext"; 
import styles from "./pagestyles/SearchPage.module.scss";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

//components
import LayoutView from "../layouts/layoutView/LayoutView"

import Container from "../components/globals/container/Container";
import NewsCard2 from "../components/newsCard2/NewsCard2";


function SearchPage (){
    const { language } = useLanguage(); 
    const location = useLocation();
    const { result } = location.state || {};
    const { t } = useTranslation();

    return(
        <LayoutView>
            <div className={styles.box}>
                <Container>
                        <h2 className={styles.title}>{t("searchResult")}</h2>
                    <div className={styles.contentWrapper}>
                        {
                            !result?.data?.items?.length ? (
                               t("noResult")
                            ) :(
                                result?.data?.items.map((item, idx) => (
                                    <NewsCard2 
                                        key={idx}
                                        date={item?.published_at}
                                        img={item?.thumbnail_path}
                                        slug={item?.slug}
                                        title={item?.title[language]}
                                        view={item?.view}
                                    />
                                )))
                        }
                    </div>
                </Container>
            </div>
        </LayoutView>
    )
}

export default SearchPage;
import React from 'react';
import styles from "./Layout.module.scss"

// components
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

import { IoIosArrowUp } from "react-icons/io";



function Layout({ children }) {

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  
  return (
    <div className={styles.layoutWrapper}>
      <Header />

      {children}

      <Footer />
      <button className={styles.scrollToTop} onClick={handleScrollToTop}><IoIosArrowUp /></button>
    </div>
  );
}

export default Layout;
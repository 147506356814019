import styles from "./Contact.module.scss";



function ContactBanner ({img, title}){
    return(
        <div className={styles.section}>
            <img src={img} alt="dsd"/>
            <h4>{title}</h4>
        </div>
    )
}
export default ContactBanner
import styles from "./RegionCard.module.scss"
import { useTranslation } from "react-i18next";

//icons
import { MdLocalPhone } from "react-icons/md";
import { GoStopwatch } from "react-icons/go";
import { IoMdMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";



function RegionCard ({img, workTime, phone, mail, name, position, location}){
    const { t } = useTranslation();
    return(
        <div className={styles.card}>
            <div className={styles.imgBox}>
                <img src={img}/>
                <div className={styles.contactWrapper}>
                    <p><GoStopwatch/><strong>{t("qabul")}: </strong> {workTime}</p>
                    <p><MdLocalPhone/><strong>{t("phone")}: </strong> {phone}</p>
                    <p><IoMdMail/><strong>{mail}</strong> </p>
                </div>
            </div>
            <h3 className={styles.name}>{name}</h3>
            <p className={styles.position}>{position}</p>
            <hr/>
            <p className={styles.address}><FaLocationDot/><strong>{t("adress")}: </strong>{location}</p>
        </div>
    )
}
export default RegionCard;